import React, { useEffect } from "react";
import "./global.css";
import { ThemeProvider } from "styled-components";
import { useTheme } from "./Hooks/useTheme";
import { Router } from "./Router";

const LIVECHAT_TOKEN = "d0546e58-04b7-4900-9b5b-f5f16456d0b7";

const App = () => {
  const theme = useTheme();

  useEffect(() => {
    window.$crisp = [];
    if (LIVECHAT_TOKEN) {
      window.CRISP_WEBSITE_ID = LIVECHAT_TOKEN;
      (function () {
        var d = document;
        var s = d.createElement("script");

        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();
    } else {
      window.CRISP_WEBSITE_ID = "";
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  );
};

export default App;
