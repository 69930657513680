const theme = {
  name: "FIND",
  colors: {
    primary: "#2eb6bb",
    secondary: "#1c1c1e",
    white: "#FFFFFF",
    black: "#010101",
    grey1: "#CBC4C4",
    grey2: "#aeb0b3",
    grey3: "#585A62",
    grey4: "#0f0f11",
    red: "#eb4e4e",
  },
  images: {
    logo: require("../Assets/images/logo.png"),
    leftMockup: require("../Assets/images/left-mockup.webp"),
    rightMockup: require("../Assets/images/right-mockup.webp"),
    qrCode: require("../Assets/images/qr-code.svg").default,
  },
  links: {
    appstore:
      "https://apps.apple.com/fr/app/find-social-rencontre/id6470507567",
    playstore: "https://play.google.com/store/apps/details?id=fr.find.findapp",
    privacy: "/cgu",
    conditions: "/cgv",
    support:
      "https://go.crisp.chat/chat/embed/?website_id=d0546e58-04b7-4900-9b5b-f5f16456d0b7",
  },
};

export default theme;
