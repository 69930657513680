import promiseRetry from "promise-retry";
import { lazy } from "react";

const importWithRetry = (importComponent) => {
  return () =>
    promiseRetry(
      (retry) => {
        return importComponent().catch(retry);
      },
      { retries: 3, minTimeout: 1000 }
    );
};

export const Home = lazy(importWithRetry(() => import("./Containers/Home")));
export const Download = lazy(
  importWithRetry(() => import("./Containers/Download"))
);
export const CGU = lazy(importWithRetry(() => import("./Containers/CGU")));
export const CGV = lazy(importWithRetry(() => import("./Containers/CGV")));
