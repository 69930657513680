import React from "react";
import { memo } from "react";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import * as routes from "./routes";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Outlet />
        <routes.Home />
      </>
    ),
  },
  {
    path: "/dl-app",
    element: <routes.Download />,
  },
  {
    path: "/cgu",
    element: <routes.CGU />,
  },
  {
    path: "/cgv",
    element: <routes.CGV />,
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
]);

export const Router = memo(() => {
  return <RouterProvider router={router} />;
});
